import React from "react";

import { RoleGuard } from "components/RoleGuard/RoleGuard";

import { Nav } from "./Nav";
import { StyledNavs } from "./styles";
import { NavsProps, NavsType } from "./types";

const Navs = ({ type = NavsType.VERTICAL, items, ...props }: NavsProps) => (
  <StyledNavs type={type} {...props}>
    {items.map((navProps, index) => (
      <RoleGuard key={index} allowedRoles={navProps.rolesCanAccess}>
        <Nav {...navProps} />
      </RoleGuard>
    ))}
  </StyledNavs>
);

Navs.displayName = "Navs";

export { Navs };
