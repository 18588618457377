import { Dropdown, DropdownItem } from "ds/Dropdown";
import { Icon } from "ds/icons";
import { down } from "ds/theme";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Placement } from "react-joyride";
import { useBreakpoint } from "styled-breakpoints/react-styled";

import { RoleGuard } from "components/RoleGuard/RoleGuard";
import { isOnboardingApplicationInProgress } from "containers/Onboarding/utils";
import { SearchInput } from "containers/Reminders/components/ReminderContacts/components/ContactSearchMenuBar/components/Autocomplete/styles";
import { OrganisationType, RoleType } from "graphql/generated/types";
import { GET_ORGANISATIONS, TOrganisationDetails, useGetOrganisationsQuery } from "graphql/organisation";
import { IUseNavigationOptions, useGlobalContext, useNavigation } from "hooks";
import { useModalContext } from "hooks/useModalContext";
import { getOrganisationByUuid, selectLastUsedNumberUuid } from "selectors/organisation";
import { insertIf } from "utils/array";
import { FeatureFlag, isEnabledFeature } from "utils/featureFlags";

export const useOrganisationMenu = () => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint(down("lg"));

  const { setOrganisationUuid, organisationUuid, logOut, UNSAFE_numberUuid } = useGlobalContext();
  const { updateModal } = useModalContext();
  const { navigateTo, getPath } = useNavigation();

  const [visible, setVisible] = useState<boolean>();

  const [search, setSearch] = useState("");
  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setSearch(search);
  };

  const { data } = useGetOrganisationsQuery();
  const { organisations } = GET_ORGANISATIONS.parse(data) ?? {};

  const currentOrganisation = getOrganisationByUuid(organisations, organisationUuid);

  const { myAccount, activeOnboardingApplication, type } = currentOrganisation || {};

  const filteredOrganisations = organisations
    ?.sort(({ name: A }, { name: B }) => (A < B ? -1 : 1))
    .filter((organisation) => organisation.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));

  const getOrganisationAnchor = (organisation: TOrganisationDetails) => {
    const organisationUuid = organisation.uuid;
    const numberUuid = selectLastUsedNumberUuid(organisation);

    return {
      href: numberUuid ? getPath("dashboard", { numberUuid }) : getPath("onboarding", { organisationUuid }),
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        setOrganisationUuid(organisationUuid);
        if (numberUuid) {
          navigateTo("inbox", { numberUuid })();
        } else {
          navigateTo("onboarding", { organisationUuid })();
        }
      },
    };
  };

  const getAnchor = (to: string, options?: IUseNavigationOptions) => ({
    href: getPath(to, options),
    onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      navigateTo(to, options)();
    },
  });

  return {
    setOrganisationMenuVisible: setVisible,
    organisationMenuProps: {
      visible,
      onShow: () => setVisible(true),
      onHide: () => setVisible(false),
      tooltip: { content: t("menu.organisation-menu") },
      "data-test": "account-drop-button",
      position: "auto-end" as Placement,
      avatar: {
        label: `${myAccount?.firstName} ${myAccount?.lastName}`,
        url: myAccount?.profilePicture!,
      },
      header: (
        <DropdownItem
          label={`${myAccount?.firstName} ${myAccount?.lastName}`}
          description={currentOrganisation?.name}
          className="guide-6"
          avatar={{ label: `${myAccount?.firstName} ${myAccount?.lastName}`, url: myAccount?.profilePicture! }}
          data-test="account-info"
        />
      ),
      children: [
        <RoleGuard allowedRoles={[]} key="1">
          <DropdownItem
            label={t("account-menu.members")}
            icon={<Icon name="FaceContentIcon" />}
            {...getAnchor(`/org/${organisationUuid}/members`)}
          />
        </RoleGuard>,
        ...insertIf(
          !!UNSAFE_numberUuid,
          <RoleGuard allowedRoles={[]} key="2">
            <DropdownItem
              label={t("account-menu.billing")}
              icon={<Icon name="CreditCard_01Icon" />}
              {...getAnchor(`/billing/${UNSAFE_numberUuid}`)}
            />
          </RoleGuard>
        ),
        ...insertIf(
          type?.includes(OrganisationType.GROUP),
          <RoleGuard allowedRoles={[]} key="3">
            {isOnboardingApplicationInProgress(activeOnboardingApplication?.status) ? (
              <DropdownItem
                label={t("account-menu.resume-number-application")}
                icon={<Icon name="PhonePlusIcon" />}
                {...getAnchor("onboarding")}
              />
            ) : (
              <DropdownItem
                label={t("account-menu.add-new-number")}
                icon={<Icon name="PhonePlusIcon" />}
                {...getAnchor("onboarding", { addNewNumber: true })}
              />
            )}
          </RoleGuard>
        ),
        <RoleGuard allowedRoles={[RoleType.BUILDER]} key="4">
          <DropdownItem
            key="4"
            label={t("account-menu.sandbox")}
            icon={<Icon name="PlusSquareIcon" />}
            {...getAnchor(`/org/${organisationUuid}/sandbox/new`)}
          />
        </RoleGuard>,
        ...insertIf(
          isEnabledFeature(FeatureFlag.MFA),
          <DropdownItem
            key="5"
            label={t("account-menu.2fa.setup")}
            icon={<Icon name="PasscodeLockIcon" />}
            onClick={() => updateModal("mfa", true)}
          />
        ),
        ...insertIf(
          isEnabledFeature(FeatureFlag.MFA),
          <DropdownItem>
            <Dropdown
              icon={<Icon name="PasscodeLockIcon" />}
              label={t("account-menu.2fa.manage")}
              position={isMobile ? "auto" : "left-start"}
              className="guide-7"
              data-test="switch-organisations"
              dropProps={{
                maxHeight: "240px",
                overflow: "auto",
                className: "drop-content",
                minHeight: "64px",
              }}
            >
              <DropdownItem key="1" label={t("account-menu.2fa.reset")} disabled />
              <DropdownItem key="2" label={t("account-menu.2fa.view-codes")} disabled />
              <DropdownItem
                key="3"
                label={t("account-menu.2fa.disable")}
                onClick={() => updateModal("disableMfa", true)}
              />
            </Dropdown>
          </DropdownItem>
        ),
      ],
      footer: [
        <DropdownItem key="1">
          <Dropdown
            icon={<Icon name="SwitchHorizontal_01Icon" />}
            label={t("account-menu.switch-organisation")}
            position={isMobile ? "auto" : "left-start"}
            className="guide-7"
            data-test="switch-organisations"
            footer={[
              <RoleGuard allowedRoles={[]} key="1">
                <DropdownItem
                  label={t("account-menu.new-organisation-onboarding")}
                  icon={<Icon name="PlusSquareIcon" />}
                  {...getAnchor("/onboarding")}
                />
              </RoleGuard>,
            ]}
            dropProps={{
              maxHeight: "240px",
              overflow: "auto",
              className: "drop-content",
              minHeight: "64px",
            }}
            header={
              Number(organisations?.length) > 5 ? (
                <SearchInput
                  value={search}
                  onChange={onSearch}
                  placeholder={t("reminders.menu.search")}
                  icon={<Icon name="SearchMdIcon" />}
                />
              ) : null
            }
          >
            {filteredOrganisations?.map((organisation) => (
              <DropdownItem
                key={organisation.uuid}
                label={organisation.name}
                avatar={{ label: organisation.name }}
                selected={organisation.uuid === organisationUuid}
                data-test="organisation-dropdown-item"
                {...getOrganisationAnchor(organisation)}
              />
            ))}
          </Dropdown>
        </DropdownItem>,
        <DropdownItem key="2" label={t("account-menu.logout")} onClick={logOut} icon={<Icon name="Lock_03Icon" />} />,
      ],
    },
  };
};
